@import "styles/variables.scss";

form.form {
  align-items: flex-start;
  height: 500px;
  overflow-y: auto;
  position: relative;
  padding-top: 16px;
  scrollbar-width: thin;
  scrollbar-color: #f2f2f2 #e2e2e2;
  @media (min-width: 1020px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  &::-webkit-scrollbar {
    width: "7px";
    height: "7px";
  }

  &::-webkit-scrollbar-track {
    background: #e2e2e2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f2f2f2;
    border-radius: 30px;
    border: 1px solid #f2f2f2;
  }
}

// .shadow {
//   position: relative;
//   &::before {
//     content: "";
//     width: calc(100% - 80px);
//     height: 0px;
//     left: 32px;
//     top: 0;
//     box-shadow: 0px 15px 25px 27px rgb(255 255 255);
//     z-index: 20000;
//     position: absolute;
//   }
// }

.btnGroup {
  display: none;
  @media (min-width: 1020px) {
    gap: 8px;
    display: flex;
    align-items: center;
    background-color: $backgroundGrey;
    border-radius: 8px;
    min-height: 40px;
    width: fit-content;
  }
}
.btnGroupItem {
  padding: 8px 16px;
  min-height: 40px;
  background-color: $backgroundGrey;
  border-radius: 8px;
  color: $darkGrey;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 16px;
  font-weight: 500;
  width: max-content;
  @media (hover: hover) {
    &:hover {
      color: $greyColor4;
    }
  }
  &.active {
    color: $greyColor4;
    background-color: $greyColor3;
  }
}

.subtitle {
  color: $blackColor;
  font-size: 1rem;
  font-weight: 500;
}
.formAvatarWrap {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.formAvatar {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  cursor: pointer;
  img {
    cursor: pointer;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 120px;
  }
}

.fields {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  section {
    width: 47%;
  }
  .time {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    section {
      width: 80px;
    }
    input {
      text-align: center;
    }
  }

  @media (min-width: 1020px) {
    flex-wrap: nowrap;
    section {
      width: 45%;
    }
  }
}

.rowAvatar {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column-reverse;
  @media (min-width: 1020px) {
    flex-direction: row;
  }
}

.formItem {
  width: 100%;
}

.formItemRow {
  border-bottom: 1px solid $greyColor3;
  padding-bottom: 16px;
  padding-top: 16px;
}

.gap {
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1020px) {
    width: auto;
  }
}

.body {
  max-width: 816px;
  padding-top: 16px;
}
.select {
  margin-left: 0px;
  width: 47%;
  @media (min-width: 1020px) {
    section {
      width: 207px;
    }
  }
}

.actions {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
}

.btnGroupPadding {
  margin: 0 auto;
}

.button {
  max-width: 312px;
  span {
    color: #fff;
  }
}
.timeWrap {
  display: flex;
  align-items: center;
  gap: 6px;
}
.label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  &.small {
    font-size: 12px;
  }
}

.offset {
  margin-bottom: 160px;
}

.fieldsRow {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;
  section {
    width: 100%;
    margin-left: 0px;
  }
  @media (min-width: 1020px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.col {
  width: 100%;
  @media (min-width: 1020px) {
    width: 50%;
  }
}

.listKnowledge {
  max-height: 295px;
  width: 100%;
}

.list {
  li {
    margin-bottom: 4px;
  }
}
.fieldsSach {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: flex-end;
  gap: 16px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 160px;
  }
}
.buttonAdd {
  height: 40px;
}

.pd32 {
  padding-top: 32px;
}

.tabel {
  width: 100%;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: unset;
  // min-width: 800px;
  td {
    border: 1px solid $greyColor3;
    background: $whiteColor;
    padding: 0px 10px;
    color: $darkGrey;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 1rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
    @media (min-width: 1020px) {
      max-width: unset;
    }
  }
  th {
    background: $backgroundGrey;
    border: 1px solid $greyColor3;
    padding: 0px 10px;
    color: $greyColor4;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 1rem;
    font-weight: 500;
    &:first-child {
      border-top-left-radius: 8px;
      width: 200px;
    }
    &:last-child {
      border-top-right-radius: 8px;
      width: 200px;
    }
  }
  tr {
    min-height: 40px;
    height: 40px;
    white-space: nowrap;
    max-width: 200px;
    @media (min-width: 1020px) {
      max-width: unset;
    }
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 8px;
          width: 200px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
          width: 200px;
        }
      }
    }
  }
}

.tableWrap {
  padding-top: 32px;
}
.filedData {
  width: 47%;
  @media screen and (min-width: 1020px) {
    width: 45%;
  }
}
.dateInputWrap {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;
  section {
    width: calc(100% - 44px);
  }
}

.calendarBrn {
  width: 40px;
  height: 40px;
  border: 2px solid #f2f2f2;
  color: #f2f2f2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    stroke: #d5d5d5;
  }
}

.popupTitle {
  color: #313131;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 32px;
  text-align: center;
}
.popupActions {
  display: flex;
  align-items: center;
  gap: 16px;
}
