@import "styles/variables.scss";

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  @media (min-width: 1020px) {
    gap: 24px;
  }
  @media (min-width: 1440px) {
    flex-direction: row;
  }
}
.paperPadding {
  padding: 8px;
  @media (min-width: 1020px) {
    padding: 16px 25px;
  }
}
.select-wrapper {
  margin-left: 0;
}
.selects {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  @media (min-width: 1020px) {
    justify-content: flex-start;
    gap: 16px;
    flex-wrap: nowrap;
  }
  section {
    min-width: 80px;
    width: 48%;
    @media (min-width: 1020px) {
      max-width: 160px;
      width: auto;
    }
    @media (min-width: 1700px) {
      max-width: 190px;
      width: clamp(100px, 9.8vw, 190px);
    }
  }
}

.search {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  border-color: $backgroundGrey;
}

.searchWrap {
  width: 100%;
  @media (min-width: 1020px) {
    max-width: 240px;
    width: clamp(160px, 12vw, 240px);
  }
}

.buttons {
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  @media (min-width: 1020px) {
    width: auto;
    gap: 16px;
  }
}

.button {
  width: 48%;
  padding: 8px 24px;
  min-height: 40px;
  @media (min-width: 1020px) {
    width: max-content;
    height: 40px;
  }
  span {
    display: flex;
    align-items: center;
    @media (min-width: 1020px) {
      display: inline;
    }
  }
}

.icon {
  margin-right: 8px;
}

button.modalClose {
  left: unset;
  right: 16px;
  top: 16px;
}

.modal {
  max-width: 816px;
  @media (min-width: 1200px) {
    width: 816px;
  }
}
