@import "styles/variables.scss";

section.dashboarHeaderActionsSelect {
  width: auto;
  margin-left: 0px;
}
.dashboarHeaderActionsCalendarContent {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
}
.dashboarHeaderActionsCalendar {
  position: relative;
}
.dashboarHeaderActionsCalendarTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $darkGrey;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: color 300ms ease;
}

.dashboarCalendar {
  position: absolute;
  top: 40px;
  transform: translateX(-30px);
  transition: all 300ms ease;
}
.dashboarCalendarOpen {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}
.dashboarCalendarClose {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.dashboarHeaderActionsCalendarOpen {
  color: $blueColor;
}
